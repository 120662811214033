import Router from 'next/router'
import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'

import allTKeys from '@teamflow/translations'
import HuddleHeader from '@teamflow/web/src/components/common/HuddleHeader'
import HuddleLoader from '@teamflow/web/src/components/common/HuddleLoader'

import { configureTranslations } from '../src/util'

const translationPage = 'login'
const tKeys = allTKeys.login

configureTranslations(['login'])

export default function Redirect() {
    const { t } = useTranslation(translationPage)

    useEffect(() => {
        void Router.push(`/login`)
    }, [])

    return (
        <>
            <HuddleHeader />
            <HuddleLoader>{t(tKeys.loading)}</HuddleLoader>
        </>
    )
}
