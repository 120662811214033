import i18n from 'i18next'
import LanguageDetector from 'i18next-browser-languagedetector'
import Backend from 'i18next-chained-backend'
import HttpApi from 'i18next-http-backend'
import LocalStorageBackend from 'i18next-localstorage-backend'
import { initReactI18next } from 'react-i18next'

import { getPublicUrl } from '@teamflow/lib'
import { DEFAULT_LOCALE, LOCALES } from '@teamflow/web/src/constants'

export function initializeFlutterWebview() {
    if (typeof window !== 'undefined') {
        window.addEventListener('flutterInAppWebViewPlatformReady', onReady)
    }
}

function onReady() {
    window.flutterInAppWebviewReady = true
}

export function configureTranslations(namespaces: string | readonly string[]) {
    if (i18n.isInitialized) {
        void i18n.loadNamespaces(namespaces)
        return
    }

    void i18n
        .use(Backend)
        .use(initReactI18next)
        .use(LanguageDetector)
        .init({
            // for all options read: https://www.i18next.com/overview/configuration-options
            interpolation: {
                escapeValue: false,
            },
            backend: {
                backends: [
                    LocalStorageBackend, // primary backend
                    HttpApi, // fallback backend
                ],
                backendOptions: [
                    {
                        /*
                            Options for the localStorageBackend.
                            Default TTL is 1 month, but as soon as we deploy a new version
                            this locales are updated. This is being controlled by
                            the COMMIT_SHA1 env var. If NEXT_PUBLIC_COMMIT_SHA is not set
                            make sure we load the latest translation files
                         */
                        prefix: 'i18next_res_',
                        expirationTime: 4 * 7 * 24 * 60 * 60 * 1000, // 1 month
                        defaultVersion:
                            process.env.NEXT_PUBLIC_COMMIT_SHA ||
                            String(Date.now()),
                    },
                    {
                        /* options for fallback backend */
                        loadPath: getPublicUrl('/locales/{{lng}}/{{ns}}.json'),
                    },
                ],
            },
            detection: {
                lookupLocalStorage: 'i18nextLng',
            },
            ns: namespaces,
            defaultNS: 'common',
            fallbackLng: DEFAULT_LOCALE,
            supportedLngs: LOCALES,
            debug: false,
            react: {
                useSuspense: false,
            },
        })
}
